<template>
  <v-select
    :value="value"
    :rules="rules"
    :items="filteredIdentities"
    :menu-props="{ maxHeight: '400' }"
    :label="label"
    @change="onChange"
  ></v-select>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsIdentitySelector",
  components: {},
  props: {
    value: {
      type: String,
      default: null
    },
    idTypes: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      filteredIdentities: []
    };
  },
  computed: {},
  created: async function() {
    this.getFilteredIdentities();
  },
  methods: {
    async getFilteredIdentities() {
      const identities = await this.getIdentitiesByidType(this.idTypes);
      // console.log(identities)
      // let identityAttrs = [];
      // for (let identity of identities) {
      //   const r = callAPI({
      //     url: this.$store.state.idp_api_url + identity.attrs,
      //     method: "GET"
      //   });
      //   identityAttrs.push(r);
      // }
      // identityAttrs = await Promise.all(identityAttrs);
      // identityAttrs = identityAttrs.map(p => p.data);
      // let filteredIdentities = [];
      // for (let [i, attrs] of identityAttrs.entries()) {
      //   const idType = attrs.find(p => p.name === "idType");
      //   const idTypeValue = idType ? idType.value : null;
      //   // console.log(idTypeValue)
      //   if (this.idTypes.includes(idTypeValue)) {
      //     filteredIdentities.push({
      //       text: identities[i].name,
      //       value: identities[i]._uri
      //     });
      //   }
      // }
      this.filteredIdentities = identities.map(p => ({
        text: p.name,
        value: p._uri
      }));
      // console.log(this.filteredIdentities)
    },
    async getIdentitiesByidType(idTypes) {
      idTypes = idTypes.join(",");
      // console.log(idTypes);
      const result = await callAPI({
        url:
          this.$store.state.idp_api_url +
          `/identities?idType=${idTypes}&limit=1000`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },
    onChange(v) {
      this.$emit("input", v);
    }
  }
};
</script>
