<template>
  <v-container fluid class="pa-8">
    <v-row class="pb-4">
      <GsNavButtons />
    </v-row>
    <v-form
      class="mb-6"
      style="width: 100%"
      v-model="valid"
      ref="form"
      v-if="client"
    >
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('name')"
            v-model="client.client_name"
            type="text"
            maxlength="256"
            :rules="formRules.client_name"
            :autofocus="true"
          />
          <v-text-field
            :label="$t('code')"
            v-model="client.appCode"
            type="text"
            maxlength="256"
            :rules="formRules.appCode"
            :autofocus="true"
          />
          <v-select
            :label="$t('type')"
            v-model="client.client_type"
            :rules="formRules.client_type"
            :items="$store.state.lookups.client_types"
            :menu-props="{ maxHeight: '400' }"
            clearable
          ></v-select>
          <v-text-field
            :label="$t('redirect_uri')"
            v-model="client.redirect_uri"
            :rules="formRules.redirect_uri"
          />
          <v-text-field
            :label="$t('optional_logo_for_authorization_page')"
            v-model="client.logoUri"
            :rules="formRules.logoUri"
          />
          <v-select
            :label="$t('attached_scopes')"
            v-model="client.scope"
            :items="scopes"
            :menu-props="{ maxHeight: '400' }"
            multiple
          ></v-select>
          <GsIdentitySelector
            :label="$t('application_or_resource_identity')"
            v-model="client.identityId"
            :rules="formRules.identityId"
            :idTypes="['application']"
          />
        </v-col>
      </v-row>

      <!-- errors -->
      <div class="d-flex flex-row" v-if="error">
        <span class="subtitle-1 mb-4 red--text"
          >{{ $t("form_invalid_text") }}: {{ error }}</span
        >
      </div>

      <!-- <actions> -->
      <div class="d-flex flex-row">
        <v-spacer />
        <!-- <v-btn class="ma-2" color="default" @click="cancel_click">
          {{ $t("cancel") }}
        </v-btn> -->
        <v-btn
          class="ma-2"
          color="primary"
          @click="save_click"
          :loading="loading"
        >
          {{ $t("save") }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsIdentitySelector from "@/components/GsIdentitySelector";
const fieldValidators = require("@/managers/fieldValidators");
import GsNavButtons from "@/components/GsNavButtons";

export default {
  name: "Client",
  components: {
    GsIdentitySelector,
    GsNavButtons
  },
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      mode: null,
      client: null,
      formRules: {
        client_name: [fieldValidators.requiredString],
        appCode: [fieldValidators.requiredString],
        client_type: [fieldValidators.requiredString],
        redirect_uri: [fieldValidators.optionalUri],
        logoUri: [fieldValidators.optionalUri],
        scope: [fieldValidators.requiredArray],
        identityId: [fieldValidators.requiredString]
      },
      valid: true,
      error: null,
      loading: false,
      scopes: []
    };
  },
  created: async function() {
    this.getScopes();
  },
  watch: {
    "$route.params.clientId": {
      immediate: true,
      handler(v) {
        if (v === "new") {
          this.mode = "create";
          this.createClient();
          return;
        }
        this.mode = "edit";
        this.init();
        this.loadClient(v);
      }
    }
  },
  async mounted() {},
  methods: {
    async init() {
      await this.setBreadCrumbs();
    },
    createClient() {
      this.client = {
        client_id: null,
        client_name: null,
        appCode: null,
        client_type: null,
        redirect_uri: null,
        logoUri: null,
        scope: null,
        identityId: null
      };
    },
    async loadClient(clientId) {
      const url = `${this.$store.state.idp_api_url}/clients/${clientId}`;
      const method = "GET";
      try {
        const response = await callAPI({ url, method });
        this.client = response.data;
        // console.log(this.client)
      } catch (err) {
        console.log(err);
      }
    },
    async cancel_click() {
      this.$router.go(-1);
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      let url = `${this.$store.state.idp_api_url}/clients`;
      if (this.mode == "edit") url += `/${this.client.client_id}`;

      const method = this.mode == "create" ? "POST" : "PATCH";
      let data = {
        client_name: this.client.client_name,
        appCode: this.client.appCode,
        client_type: this.client.client_type,
        scope: this.client.scope,
        identityId: this.client.identityId
      };

      if (this.client.redirect_uri)
        data.redirect_uri = this.client.redirect_uri;
      if (this.client.logoUri) data.logoUri = this.client.logoUri;

      try {
        this.loading = true;
        const response = await callAPI({ url, method, data });
        // console.log(response.status)
        if (response.status == 204 || response.status == 200) {
          this.$router.go(-1);
        }

        // this.client = response.data
        // console.log(this.client)
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        }
      } finally {
        this.loading = false;
      }
    },
    async getScopes() {
      const result = await callAPI({
        url: this.$store.state.idp_api_url + "/scopes?limit=1000",
        method: "GET"
      });
      this.scopes = result.data.map(p => ({
        value: p._uri,
        text: p.scopeName
      }));
    }
  }
};
</script>
